import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo_text_transparent.png"
import { LatestNews } from "./LatestNews"

const Header = props => (
  <header id="header" style={props.timeout ? { display: "none" } : {}}>
    <div className="logo">
      <img src={logo} alt="Covvyのロゴ" />
    </div>
    <div className="content">
      <div className="inner">
        <p>
          兵庫県神戸市に拠点を置くシステム開発企業です。Webアプリ・ネイティブアプリ・業務システムの開発などを行っています。
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("intro")
            }}
          >
            会社概要
          </button>
        </li>
        {/* <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
          */}
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("news")
            }}
          >
            ニュース
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("work")
            }}
          >
            事業内容
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("works")
            }}
          >
            制作実績
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("recruit")
            }}
          >
            採用情報
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle("contact")
            }}
          >
            お問い合わせ
          </button>
        </li>
      </ul>
    </nav>
    <LatestNews
      openNews={() => {
        props.onOpenArticle("news")
      }}
    />
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
