import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import matakul from "../images/imageMatakul.png"

export const Works = () => {
  return (
    <div className="contents">
      <div className="contents-section">
        <h2>Webアプリ開発</h2>
        <a
          className="contents-title-link"
          href="https://matakul.jp/"
          target="_blank"
          rel="noreferrer noopener"
        >
          MATAKUL
        </a>
        <p>
          回数券の管理システム。店舗様用管理画面の開発とお客様が利用するアプリケーションの開発、運用、保守を行っています。
        </p>
        <div className="appImage">
          <StaticImage
            src="../images/imageMatakul.png"
            alt="MATAKULアプリ画面"
          />
        </div>
        <p className="contents-title">SNSアプリ</p>
        <p>
          地域を限定したシェアリングや情報共有を目的としたSNSアプリケーションの開発を行っています。
        </p>
      </div>
      <div className="contents-section">
        <h2>システム開発</h2>
        <p className="contents-title">注文管理システム</p>
        <p>
          お客様からの注文を受け付け後、お客様へ配送を行うまでのフローをシステム化し、効率化するシステムを構築、運用しています。
        </p>
        <p className="contents-title">3Dウェアシミュレータ</p>
        <p>
          WebGLを用いたウェアのカスタマイズをブラウザで実行できるシステムを開発、運用しています。
        </p>
      </div>
      <div className="contents-section">
        <h2>ECショップの制作運営</h2>
        <a
          className="contents-title-link"
          href="https://sachi-flower.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          sachi
        </a>
        <p>おはかまいりを主軸にしたECサイトをShopifyで構築し運用しています。</p>
        <div className="appImage-sachi">
          <StaticImage src="../images/sachi.png" />
          <StaticImage src="../images/sachi-menu.png" />
        </div>
        <div className="makuake">
          <p>
            クラウドファンディングサイト
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.makuake.com/project/sachi-flower/"
              >
                Makuake
              </a>
            </span>
            で目標金額を達成!
          </p>
          <iframe
            scrolling="no"
            frameborder="0"
            height={200}
            width={460}
            src="https://www.makuake.com/widget/project/sachi-flower/wide"
            allowTransparency="true"
          />
        </div>
      </div>
      <div className="contents-section">
        <h2>Webデザインおよびサイト作成</h2>
        <div className="site-section">
          <div className="site-section-container">
            <a
              className="site-title"
              href="https://hokuto-otsu.jp/"
              target="_blank"
              rel="noreferrer noopener"
            >
              北斗妙智会
            </a>
            <div className="site-image">
              <StaticImage src="../images/hokuto-otsu.png" />
            </div>
            <p className="site-type">Design / Coding</p>
            <p className="site-description">
              和のデザインで落ち着いた雰囲気を出し、行事予定などの情報を見やすくシンプルに構成したサイトを作成。
            </p>
          </div>
          <div className="site-section-container">
            <a
              className="site-title"
              href="https://www.pepelabollc.com/"
              target="_blank"
              rel="noreferrer noopener"
            >
              PEPELABO
            </a>
            <div className="site-image">
              <StaticImage src="../images/pepelabo.png" />
            </div>
            <p className="site-type">Coding</p>
            <p className="site-description">
              エッジを効かせたデザインでシンプルさと力強さを強調し会社のテーマを表現したサイト。
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
