import * as React from "react"
import { NEWS_CONTENTS } from "../contents/newsContents"

export const LatestNews = ({ openNews }) => {
  return (
    <div className="latestNewsContainer">
      <h4 className="title">最新ニュース</h4>
      {NEWS_CONTENTS.map((doc, index) => {
        return (
          <>
            {index > 1 ? null : (
              <div key={index} className="">
                <p className="latestNewsDate">{doc.date}</p>
                <p className="latestNewsContents">{doc.contents}</p>
              </div>
            )}
          </>
        )
      })}
      <button
        className="seeMoreButton"
        onClick={() => {
          openNews()
        }}
      >
        もっと見る
      </button>
      {/* {console.log(openNews)} */}
    </div>
  )
}

// LatestNews.propTypes = {
//   onOpenArticle: PropTypes.func,
// }

export default LatestNews
