import PropTypes from "prop-types"
import React from "react"
import pic01 from "../images/pic01.jpg"
import pic02 from "../images/pic02.jpg"
import pic03 from "../images/pic03.jpg"
import { Works } from "../components/Works"
import { News } from "../components/News"
import { Recruit } from "../components/Recruit"

class Main extends React.Component {
  render() {
    let close = (
      <button
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
        aria-label="Close"
      ></button>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: "flex" } : { display: "none" }}
      >
        <article
          id="intro"
          className={`${this.props.article === "intro" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">会社概要</h2>
          <table>
            <tbody>
              <tr>
                <th>会社名</th>
                <td>株式会社Covvy</td>
              </tr>
              <tr>
                <th>設立</th>
                <td>2021年7月7日</td>
              </tr>
              <tr>
                <th>資本金</th>
                <td>1,000,000円</td>
              </tr>
              <tr>
                <th>代表者</th>
                <td>古沢宏太</td>
              </tr>
            </tbody>
          </table>

          {/* <span className="image main">
            <img src={pic01} alt="" />
          </span> */}
          {close}
        </article>
        <article
          id="news"
          className={`${this.props.article === "news" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">NEWS</h2>
          <News />

          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === "work" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">事業内容</h2>
          {/* <span className="image main">
            <img src={pic02} alt="" />
          </span> */}
          <div className="text-container">
            <p className="title">
              Covvyでは各種システムの受託開発を行っております。{" "}
            </p>
            <ul className="text-container">
              <li> Webサイト作成 </li>
              <li>Webデザイン</li>
              <li> Wordpress開発 </li>
              <li> スマートフォンアプリ開発 </li>
              <li> 業務システム開発 </li>
              <li>POSシステム開発</li>
            </ul>
          </div>
          <ul className="text-container">
            <li className="title">技術スタック / 開発環境 </li>
            <li>
              Node.js, React, Next.js, TypeScript, Prisma, tRPC, Auth0, Firebase
            </li>
          </ul>
          <ul className="text-container">
            <li className="title">インフラ </li>
            <li>GCP, Vercel</li>
          </ul>
          <ul className="text-container">
            <li className="title">モニタリング・ログ </li>
            <li>Sentry</li>
          </ul>

          <ul className="text-container">
            <li className="title">デザイン</li>
            <li>XD</li>
            <li>Figma/FigJam</li>
          </ul>

          {close}
        </article>

        <article
          id="works"
          className={`${this.props.article === "works" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">制作実績</h2>
          <Works />

          {close}
        </article>
        <article
          id="recruit"
          className={`${this.props.article === "recruit" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">採用情報</h2>
          <Recruit />

          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === "about" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Lorem ipsum dolor sit amet, consectetur et adipiscing elit. Praesent
            eleifend dignissim arcu, at eleifend sapien imperdiet ac. Aliquam
            erat volutpat. Praesent urna nisi, fringila lorem et vehicula
            lacinia quam. Integer sollicitudin mauris nec lorem luctus ultrices.
            Aliquam libero et malesuada fames ac ante ipsum primis in faucibus.
            Cras viverra ligula sit amet ex mollis mattis lorem ipsum dolor sit
            amet.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === "contact" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">お問い合わせ</h2>
          <p>
            <a href="mailto: ">hello@covvy.dev</a>までご連絡ください
          </p>
          {/* <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <div className="field half first">
              <label htmlFor="name">お名前</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">メールアドレス</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">お問い合わせ内容</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="送信" className="special" />
              </li>
              <li>
                <input type="reset" value="リセット" />
              </li>
            </ul>
          </form> */}
          {/* <ul className="icons">
            <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul> */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
