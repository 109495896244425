import * as React from "react"
import { NEWS_CONTENTS } from "../contents/newsContents"

export const News = () => {
  return (
    <div className="contents">
      <div className="contents-section">
        {NEWS_CONTENTS.map(doc => {
          return (
            <>
              <p className="date">{doc.date}</p>
              <p className="newsContents">{doc.contents}</p>
            </>
          )
        })}
      </div>
    </div>
  )
}
