import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import matakul from "../images/imageMatakul.png"

export const Recruit = () => {
  return (
    <div className="contents">

      <div className="contents-section">
        <h2>募集職種</h2>
      </div>

      <div className="contents-section">
        <a
          className="contents-title-link"
          href="https://jp.indeed.com/cmp/(%E6%A0%AA)covvy/jobs?jk=a562481bdc84597d&start=0"
          target="_blank"
          rel="noreferrer noopener"
        >
          Webエンジニア ジュニアクラス
        </a>
        <p>
          業務経験が未経験~1年程度の方を対象とした、Webエンジニアのポジションです。
        </p>
      </div>

      <div className="contents-section">
        <a
          className="contents-title-link"
          href="https://jp.indeed.com/cmp/(%E6%A0%AA)covvy/jobs?jk=8987dca0ce2239d8&start=0"
          target="_blank"
          rel="noreferrer noopener"
        >
          フロントエンドエンジニア ジュニアクラス
        </a>
        <p>
          業務経験が未経験~2年程度の方を対象とした、フロントエンドエンジニアのポジションです。
        </p>
      </div>

      <div className="contents-section">
        <a
          className="contents-title-link"
          href="https://jp.indeed.com/cmp/(%E6%A0%AA)covvy/jobs?jk=15e01a2722806da4&start=0"
          target="_blank"
          rel="noreferrer noopener"
        >
          フロントエンジニア シニアクラス
        </a>
        <p>
          業務経験が3年~程度の方を対象とした、フロントエンドエンジニアのポジションです。
        </p>
      </div>

      <div className="contents-section">
        <a
          className="contents-title-link"
          href="https://jp.indeed.com/cmp/(%E6%A0%AA)covvy/jobs?jk=e3fb0f05f81ebd53&start=0"
          target="_blank"
          rel="noreferrer noopener"
        >
          バックエンドエンジニア
        </a>
        <p>
          業務経験が1年~程度の方を対象とした、バックエンドエンドエンジニアのポジションです。
        </p>
      </div>
    </div>
  )
}
